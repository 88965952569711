import { template as template_004a832841ca482ab2ddac1ba7c31b77 } from "@ember/template-compiler";
const FKLabel = template_004a832841ca482ab2ddac1ba7c31b77(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
