import { template as template_0dbe4a8013ce4b69bc924c10c32ced98 } from "@ember/template-compiler";
const WelcomeHeader = template_0dbe4a8013ce4b69bc924c10c32ced98(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
