import { template as template_90cb67dd1c604889ab4864d651be82f4 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_90cb67dd1c604889ab4864d651be82f4(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
