import { template as template_a15e256e2ec14c35be4a3d7fb446dacd } from "@ember/template-compiler";
const FKControlMenuContainer = template_a15e256e2ec14c35be4a3d7fb446dacd(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
